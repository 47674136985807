import { isEmpty } from "lodash";
import {
  InteractionRequiredAuthError,
  SilentRequest,
} from "@azure/msal-browser";
import { msalInstance } from "index";
import { toast } from "@vilocnv/allsetra-core";
import * as Sentry from "@sentry/react";
import { clearIndexedDB } from "../helpers";

class AzureMsal {
  static acquireToken = async () => {
    const account = msalInstance.getAllAccounts()[0];
    const redirectUri = window.location.origin;

    const accessTokenRequest: SilentRequest = {
      scopes: [
        "openid",
        "profile",
        "offline_access",
        "https://graph.microsoft.com/.default",
      ],
      account: account,
      redirectUri: redirectUri,
    };

    try {
      const accessTokenResponse = await msalInstance.acquireTokenSilent(
        accessTokenRequest
      );

      const exp = accessTokenResponse.account?.idTokenClaims?.exp;

      if (exp && exp < Date.now() / 1000) {
        AzureMsal.logoutUser();
      }

      return accessTokenResponse;
    } catch (error) {
      console.log(error);

      if (error instanceof InteractionRequiredAuthError) {
        return msalInstance.acquireTokenRedirect(accessTokenRequest);
      } else {
        AzureMsal.logoutUser();
      }
    }
  };

  static logoutOnTokenExp = () => {
    const account = msalInstance.getActiveAccount();

    if (isEmpty(account)) {
      AzureMsal.logoutUser();
    } else {
      const exp: number | undefined = account.idTokenClaims?.exp;

      if (exp && exp < Date.now() / 1000) {
        AzureMsal.logoutUser();
      }
    }
  };

  static logoutUser = async () => {
    toast.info("User session is expired.");
    await clearIndexedDB();
    Sentry.setUser(null);
    await clearIndexedDB();
    window.localStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" });
  };
}

export default AzureMsal;
